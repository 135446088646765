<template>
    <div>
        <div class="min-h-screen flex flex-col bg-white dark:bg-gray-900">
            <Header class="bg-white dark:bg-gray-900" />
            <main class="flex grow">
                <div class="flex grow max-w-7xl mx-auto px-4 sm:gap-x-6 sm:px-6 lg:px-8">
                    <!-- Your content -->
                    <slot />
                </div>
            </main>
        </div>
    </div>
</template>

<script setup>
import Header from "@/components/layouts/Frontend/Header";

useHead({
    bodyAttrs: {
        class: 'h-full bg-gray-50 overflow-unset'
    },
    htmlAttrs: {
        class: 'h-full'
    }
})
</script>
